import React from "react";
import Signup from "../components/UI/Signup";


const SignupPage = () => {

    return (
        <div>
            <Signup />
        </div>
    )
};


export default SignupPage;