import React, { useState, useEffect } from 'react';

const Logo = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  return (
    <div className="fixed top-4 left-4 z-50">
      <h1 className={`text-4xl font-bold transition-all duration-500 ease-in-out ${
        scrolled ? 'text-blue-600' : 'text-gray-800'
      }`}>
        {scrolled ? 'LAI' : 'Learn'}
        <span className={`inline-block transition-transform duration-500 ease-in-out ${
          scrolled ? 'rotate-180' : 'rotate-0'
        }`}>
          {scrolled ? '🧠' : '📚'}
        </span>
      </h1>
    </div>
  );
};

export default Logo;
