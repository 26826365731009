import React from "react";
import { Link } from "react-router-dom";

const IELTSHome = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center mb-8">Welcome to IELTS AI Assistant</h1>
      <p className="text-xl text-center mb-12">
        Boost your IELTS preparation with our AI-powered tools and resources.
      </p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <FeatureCard
          title="Writing Practice"
          description="Get instant feedback on your essays with our AI-powered writing assistant."
          link="/writing"
        />
        <FeatureCard
          title="Speaking Simulation"
          description="Practice speaking with our AI interviewer and receive pronunciation feedback."
          link="/speaking"
        />
        <FeatureCard
          title="Reading Comprehension"
          description="Enhance your reading skills with AI-generated questions and explanations."
          link="/reading"
        />
        <FeatureCard
          title="Listening Exercises"
          description="Improve your listening skills with AI-curated audio content and quizzes."
          link="/listening"
        />
      </div>
      
      <div className="mt-16 text-center">
        <Link
          to="/signup"
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg text-lg transition duration-300"
        >
          Get Started
        </Link>
      </div>
    </div>
  );
};

const FeatureCard = ({ title, description, link }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300">
      <h2 className="text-2xl font-semibold mb-4">{title}</h2>
      <p className="text-gray-600 mb-6">{description}</p>
      <Link
        to={link}
        className="text-blue-600 hover:text-blue-800 font-medium"
      >
        Learn More &rarr;
      </Link>
    </div>
  );
};

export default IELTSHome;
