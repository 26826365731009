import React from "react";

const Home = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      AI22 Labs Classroom
    </div>
  );
};

export default Home;
